import { Menu } from "antd";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import Icon from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";

import Sider from "antd/lib/layout/Sider";
import {
  BellIcon,
  CardIcon,
  CouponIcon,
  DishIcon,
  GroupIcon,
  ListIcon,
  MenuIcon,
  NewsIcon,
  OrderIcon,
  PhoneIcon,
  RankingIcon,
  StoreIcon,
  UserIcon,
} from "assets/icon";
import { LogoApp } from "assets/images";
import { PermissionStatus, Permissions } from "common/enum";
import useStore from "store/store";
import { text } from "utils/const";

const { SubMenu } = Menu;

const checkVisiblePage = (key: number, permission: any) => {
  return [PermissionStatus.EDIT, PermissionStatus.ONLY_READ].includes(
    Number(permission[key])
  );
};
export default function SideNav() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const toggleCollapsed = () => setCollapsed(!collapsed);
  const [selectedKey, setSelectedKey] = useState("1");
  const SIDE_NAV_WIDTH = 204;
  const permission = useStore((state) => state.permissions);

  const routes = [
    {
      key: "1",
      text: "スタッフ管理",
      url: "/staffs",
      icon: <Icon component={() => <UserIcon />} />,
      visible: checkVisiblePage(Permissions.STAFF_MANAGEMENT, permission),
    },
    {
      key: "2",
      text: "ユーザー管理",
      url: "/users",
      icon: <Icon component={() => <GroupIcon />} />,
      visible: checkVisiblePage(Permissions.MEMBER_MANAGEMENT, permission),
    },
    {
      key: "12",
      text: "店舗管理",
      url: "/stores",
      icon: <Icon component={() => <StoreIcon />} />,
      visible: checkVisiblePage(Permissions.RESTAURANT_MANAGEMENT, permission),
    },
    {
      key: "13",
      text: text.storeMenuManage,
      url: "/group-menu",
      icon: <Icon component={() => <MenuIcon />} />,
      visible: checkVisiblePage(Permissions.RESTAURANT_MANAGEMENT, permission),
    },
    {
      key: "3",
      text: "メニュー管理",
      url: "/menu",
      icon: <Icon component={() => <DishIcon />} />,
      visible: checkVisiblePage(Permissions.MENU_MANAGEMENT, permission),
    },
    {
      key: "4",
      text: "注文管理",
      url: "/order",
      icon: <Icon component={() => <OrderIcon />} />,
      visible: checkVisiblePage(Permissions.ORDER_MANAGEMENT, permission),
    },
    {
      key: "5",
      text: "スタンプカード管理",
      url: "/card-stamp",
      icon: <Icon component={() => <CardIcon />} />,
      visible: checkVisiblePage(Permissions.STAMP_MANAGEMENT, permission),
    },
    {
      key: "6",
      text: "クーポン管理",
      url: "/coupon",
      icon: <Icon component={() => <CouponIcon />} />,
      visible: checkVisiblePage(Permissions.COUPON_MANAGEMENT, permission),
    },
    {
      key: "7",
      text: "通知管理",
      icon: <Icon component={() => <BellIcon />} />,
      visible: checkVisiblePage(
        Permissions.NOTIFICATION_MANAGEMENT,
        permission
      ),
      children: [
        {
          key: "7.1",
          text: "普通通知",
          url: "/notification",
          visible: true,
        },
        {
          key: "7.2",
          text: "特定通知",
          url: "/specific-notification",
          visible: true,
        },
      ],
    },
    {
      key: "8",
      text: "ニュース管理",
      url: "/news",
      icon: <Icon component={() => <NewsIcon />} />,
      visible: checkVisiblePage(Permissions.NEWS_MANAGEMENT, permission),
    },
    {
      key: "9",
      text: "ランク管理",
      url: "/ranking",
      icon: <Icon component={() => <RankingIcon />} />,
      visible: checkVisiblePage(Permissions.RANKING_MANAGEMENT, permission),
    },
    {
      key: "10",
      text: "問い合わせ管理",
      url: "/contact",
      icon: <Icon component={() => <PhoneIcon />} />,
      visible: checkVisiblePage(Permissions.CONTACT_MANAGEMENT, permission),
    },

    {
      key: "11",
      text: "設定",
      icon: <Icon component={() => <ListIcon />} />,
      visible: checkVisiblePage(Permissions.RESOURCE_MANAGEMENT, permission),
      children: [
        {
          key: "11.1",
          text: "カテゴリ",
          url: "/category",
          visible: true,
        },
        {
          key: "11.2",
          text: "サブカテゴリ",
          url: "/subcategory",
          visible: true,
        },
        {
          key: "11.3",
          text: text.SNS,
          url: "/sns",
          visible: true,
        },
        {
          key: "11.4",
          text: text.banner,
          url: "/banner",
          visible: true,
        },
        {
          key: "11.5",
          text: text.config,
          url: "/configs",
          visible: true,
        },
        {
          key: "11.6",
          text: text.templateMail,
          url: "/template-mail",
          visible: true,
        },
        {
          key: "11.7",
          text: text.statistic,
          url: "/statistic/stamp",
          visible: true,
        },
        {
          key: "11.8",
          text: text.statisticNotificationCoupon,
          url: "/statistic/notification-coupon",
          visible: true,
        },
        {
          key: "11.9",
          text: text.statisticStampCoupon,
          url: "/statistic/stamp-coupon",
          visible: true,
        },
      ],
    },
  ];

  useEffect(() => {
    routes.forEach((route) => {
      if (location.pathname.startsWith(route.url || "###")) {
        setSelectedKey(route.key);
      }
      if (route.children) {
        route.children.forEach((childRoute) => {
          if (location.pathname.startsWith(childRoute.url || "###")) {
            setSelectedKey(childRoute.key);
          }
        });
      }
    });
  }, [location.pathname]);
  useEffect(() => {
    const firstVisibleRoute: any = routes.find((route) => route.visible);
    if (location.pathname === "/" && firstVisibleRoute?.visible) {
      if (firstVisibleRoute?.children) {
        navigate(firstVisibleRoute?.children[0]?.url);
      } else {
        navigate(firstVisibleRoute?.url);
      }
    }
  }, [location.pathname, permission]);

  return (
    <div className={styles.side_nav}>
      <div className={styles.logo_container}>
        <Link className={styles.logo} to="/">
          <img draggable="false" src={LogoApp} className={styles.logo__img} />
          {/* <Logo /> */}
        </Link>
      </div>
      <Sider
        width={SIDE_NAV_WIDTH}
        className={`${styles.side_nav__sider} scroll`}
      >
        <Menu
          selectedKeys={[selectedKey]}
          defaultOpenKeys={["3"]}
          mode="inline"
        >
          {routes.map((route) => {
            if (route.visible === false) {
              return null;
            }
            if (route.children) {
              return (
                <SubMenu key={route.key} icon={route.icon} title={route.text}>
                  {route.children?.map((childRoute) => {
                    if (childRoute.visible === false) {
                      return null;
                    }
                    return (
                      <Menu.Item key={childRoute.key}>
                        <Link to={childRoute.url}>{childRoute.text}</Link>
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              );
            }
            return (
              <Menu.Item key={route.key} icon={route.icon}>
                <Link to={route.url}>{route.text}</Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
    </div>
  );
}
