import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import App from "./App";
import "helper/i18n";
import jaJP from "antd/lib/locale/ja_JP";
import "moment/locale/ja";

import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd";

ConfigProvider.config({
  theme: {
    primaryColor: "#1D0157",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider
      locale={jaJP}
      autoInsertSpaceInButton={false}
      getPopupContainer={(trigger) => {
        if (
          trigger?.className?.includes("ant-select-selector") ||
          trigger?.className?.includes("ant-picker")
        )
          return trigger?.parentNode;
        return document?.body as any;
      }}
    >
      <App />
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
