import React, { lazy, Suspense, useEffect, useRef } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";
import styles from "./styles.module.scss";
import PageHeader from "components/PageHeader";
import SideNav from "components/SideNav";
import { Spin } from "antd";
import { useQuery } from "react-query";
import { queryKey } from "utils/queryUniqueKey";
import { getListResource } from "api/common";
import { getProfile } from "api/authentication";
import useStore from "store/store";
// Staff
const Staff = lazy(() => import("pages/Staff"));
const DetailStaff = lazy(() => import("pages/Staff/DetailStaff"));

//My page
const MyPage = lazy(() => import("pages/MyPage"));

//Store
const Stores = lazy(() => import("pages/Store"));

//StoreMenuGroup
const StoreMenuGroup = lazy(() => import("pages/StoreMenuGroup"));
const AddStoreMenuGroup = lazy(
  () => import("pages/StoreMenuGroup/components/AddMenuGroup")
);
const EditStoreMenuGroup = lazy(
  () => import("pages/StoreMenuGroup/components/EditMenuGroup")
);
const CopyStoreMenuGroup = lazy(
  () => import("pages/StoreMenuGroup/components/CopyMenuGroup")
);
const ViewStoreMenuGroup = lazy(
  () => import("pages/StoreMenuGroup/components/ViewMenuGroup")
);

// User
const User = lazy(() => import("pages/User"));
const DetailUser = lazy(() => import("pages/User/DetailUser"));
const DetailCouponUser = lazy(() => import("pages/User/DetailCouponUser"));
const DetailCardStampUser = lazy(
  () => import("pages/User/DetailCardStampUser")
);

//Menu
const Menu = lazy(() => import("pages/Menu"));
const AddMenu = lazy(() => import("pages/Menu/components/AddMenu"));
const EditMenu = lazy(() => import("pages/Menu/components/EditMenu"));
const DetailMenu = lazy(() => import("pages/Menu/components/DetailMenu"));

//Order
const Order = lazy(() => import("pages/Order"));
const DetailOrder = lazy(() => import("pages/Order/components/DetailOrder"));
const CardStamp = lazy(() => import("pages/CardStamp"));
const AddCardStamp = lazy(
  () => import("pages/CardStamp/components/AddCardStamp")
);
const EditCardStamp = lazy(
  () => import("pages/CardStamp/components/EditCardStamp")
);
const DetailCardStamp = lazy(
  () => import("pages/CardStamp/components/DetailCardStamp")
);
const CopyStamp = lazy(() => import("pages/CardStamp/components/CopyStamp"));

//Coupon
const Coupon = lazy(() => import("pages/Coupon"));
const AddCoupon = lazy(() => import("pages/Coupon/components/AddCoupon"));
const EditCoupon = lazy(() => import("pages/Coupon/components/EditCoupon"));
const DetailCoupon = lazy(() => import("pages/Coupon/components/DetailCoupon"));
const CopyCoupon = lazy(() => import("pages/Coupon/components/CopyCoupon"));

// Notification
const Notification = lazy(() => import("pages/Notification"));
const AddNotification = lazy(
  () => import("pages/Notification/components/AddNotification")
);
const EditNotification = lazy(
  () => import("pages/Notification/components/EditNotification")
);
const DetailNotification = lazy(
  () => import("pages/Notification/components/DetailNotification")
);
const SpecificNotification = lazy(() => import("pages/SpecificNotification"));

//News
const News = lazy(() => import("pages/News"));
const AddNew = lazy(() => import("pages/News/components/AddNews"));
const EditNew = lazy(() => import("pages/News/components/EditNews"));
const DetailNew = lazy(() => import("pages/News/components/DetailNews"));

// Resources
const Category = lazy(() => import("pages/Category"));
const Subcategory = lazy(() => import("pages/Subcategory"));
const SNS = lazy(() => import("pages/SNS"));
const Banner = lazy(() => import("pages/Banner"));
const Configs = lazy(() => import("pages/Configs"));
const Ranking = lazy(() => import("pages/Ranking"));
const Contact = lazy(() => import("pages/Contact"));
const DetailContact = lazy(
  () => import("pages/Contact/components/EditContact")
);
const TemplateMail = lazy(() => import("pages/TemplateMail"));
const AddCouponAdmin = lazy(() => import("pages/CouponAdmin"));
const Statistic = lazy(() => import("pages/Statistic/Stamp"));
const StatisticCouponNotification = lazy(
  () => import("pages/Statistic/CouponNotification")
);
const StatisticCouponStamp = lazy(() => import("pages/Statistic/CouponStamp"));

export default function AuthWrapper() {
  const { pathname } = useLocation();
  const refPageContent = useRef<HTMLDivElement>(null);
  const token = Cookies.get("token") || "";
  const isAuthenticated = Boolean(token);
  const setPermissions = useStore((state) => state.setPermission);

  useQuery([queryKey.listResource], () => getListResource(), {
    enabled: isAuthenticated,
  });
  useQuery([queryKey.profile], () => getProfile(), {
    enabled: isAuthenticated,
    onSuccess: ({ data }: any) => {
      let permissions: any = {};
      data.userPermission?.forEach((item: any) => {
        permissions[item?.permission?.id] = item?.status;
      });
      setPermissions(permissions);
    },
  });

  useEffect(() => {
    if (refPageContent?.current) {
      refPageContent.current.scrollTo(0, 0);
    }
  }, [pathname]);

  if (!isAuthenticated) return <Navigate to="/login" />;

  return (
    <div className={styles.pageWrapper}>
      <SideNav />
      <div className={styles.mainWrapper}>
        <PageHeader />

        <div className={styles.pageContent} ref={refPageContent}>
          <Suspense
            fallback={
              <div className="spin">
                <Spin />
              </div>
            }
          >
            <Routes>
              {/* Staff */}
              <Route path="/staffs" element={<Staff />} />
              <Route path="/staff/:id" element={<DetailStaff />} />
              {/* My page */}
              <Route path="/mypage" element={<MyPage />} />
              {/* Users */}
              <Route path="/users" element={<User />} />
              <Route path="/users/:id" element={<DetailUser />} />
              <Route
                path="/users/:userId/coupon/:couponId"
                element={<DetailCouponUser />}
              />
              <Route
                path="/users/:userId/card-stamp/:stampId"
                element={<DetailCardStampUser />}
              />
              {/* Stores */}
              <Route path="/stores" element={<Stores />} />
              {/* StoreMenuGroup */}
              <Route path="/group-menu" element={<StoreMenuGroup />} />
              <Route path="/group-menu/add" element={<AddStoreMenuGroup />} />
              <Route
                path="/group-menu/add/:id"
                element={<CopyStoreMenuGroup />}
              />
              <Route
                path="/group-menu/view/:id"
                element={<ViewStoreMenuGroup />}
              />
              <Route
                path="/group-menu/edit/:id"
                element={<EditStoreMenuGroup />}
              />
              {/* Menu */}
              <Route path="/menu" element={<Menu />} />
              <Route path="/menu/add" element={<AddMenu />} />\
              <Route path="/menu/detail/:id" element={<EditMenu />} />
              <Route path="/menu/detail/view/:id" element={<DetailMenu />} />
              {/* Orders */}
              <Route path="/order" element={<Order />} />
              <Route path="/order/:id" element={<DetailOrder />} />
              {/* Card stamp */}
              <Route path="/card-stamp" element={<CardStamp />} />
              <Route path="/card-stamp/add" element={<AddCardStamp />} />
              <Route path="/card-stamp/add/:id" element={<CopyStamp />} />
              <Route
                path="/card-stamp/detail/:id"
                element={<EditCardStamp />}
              />
              <Route
                path="/card-stamp/detail/view/:id"
                element={<DetailCardStamp />}
              />
              {/* Coupons */}
              <Route path="/coupon" element={<Coupon />} />
              <Route path="/coupon/add" element={<AddCoupon />} />
              <Route path="/coupon/add/:id" element={<CopyCoupon />} />
              <Route path="/coupon/detail/:id" element={<EditCoupon />} />
              <Route
                path="/coupon/detail/view/:id"
                element={<DetailCoupon />}
              />
              {/* Notification */}
              <Route path="/notification" element={<Notification />} />
              <Route path="/notification/add" element={<AddNotification />} />
              <Route
                path="/notification/detail/:id"
                element={<EditNotification />}
              />
              <Route
                path="/notification/detail/view/:id"
                element={<DetailNotification />}
              />
              <Route
                path="/specific-notification"
                element={<SpecificNotification />}
              />
              {/* News */}
              <Route path="/news" element={<News />} />
              <Route path="/news/add" element={<AddNew />} />
              <Route path="/news/detail/:id" element={<EditNew />} />
              <Route path="/news/detail/view/:id" element={<DetailNew />} />
              {/* Ranking */}
              <Route path="/ranking" element={<Ranking />} />
              {/* Contact */}
              <Route path="/contact" element={<Contact />} />
              <Route path="/contact/:id" element={<DetailContact />} />
              {/* Category */}
              <Route path="/category" element={<Category />} />
              <Route path="/subcategory" element={<Subcategory />} />
              {/* Resources */}
              <Route path="/sns" element={<SNS />} />
              <Route path="/banner" element={<Banner />} />
              <Route path="/configs" element={<Configs />} />
              <Route path="/template-mail" element={<TemplateMail />} />
              <Route path="/coupon-admin" element={<AddCouponAdmin />} />
              <Route path="/statistic/stamp" element={<Statistic />} />
              <Route
                path="/statistic/notification-coupon"
                element={<StatisticCouponNotification />}
              />
              <Route
                path="/statistic/stamp-coupon"
                element={<StatisticCouponStamp />}
              />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
}
