import { sendGet, sendPost } from "./axios";

// eslint-disable-next-line import/prefer-default-export
export const getListResource = () => sendGet("/common/resources");

export const uploadImage = (file: File) => {
  const formData = new FormData();
  formData.append("files", file);
  return sendPost("/common/upload", formData);
};

export const clearCache = () => sendGet("/common/clear-cache");
