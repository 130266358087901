import { PermissionStatus } from "common/enum";
import create from "zustand";

interface PermissionState {
  permissions: { [key: number]: number };
  setPermission: (data: { [key: number]: number }) => void;
}

const useStore = create<PermissionState>((set, get) => ({
  permissions: {},
  setPermission: (data: { [key: number]: number }) =>
    set((_state) => ({ permissions: data })),
}));

export default useStore;
