import React, { useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import { Menu, Dropdown, Image, Button, Avatar } from "antd";
import { useQuery, useQueryClient } from "react-query";
import { assignDomain } from "helper";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { CaretDownOutlined } from "@ant-design/icons";

import { AvatarDefault, LogoApp } from "assets/images";
import { queryKey } from "utils/queryUniqueKey";

export default function PageHeader() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const data: any = queryClient.getQueryData(queryKey.profile);
  const profile = data?.data;
  // const { data: listLanguages } = useQuery("listLanguages", () =>
  //   getListLanguage()
  // );
  const listLanguagesCopy: any = [
    { code: "vi", name: "Vietnamese" },
    { code: "ja", name: "Japanese" },
  ];

  // let img = '';
  // listLanguagesCopy?.data.forEach((language: any) => {
  //   if (language.code === i18next.language) {
  //     img = language.flagIcon;
  //   }
  // });
  // const getListLanguageRender = () => {
  //   const listDataLanguageMap = listLanguages?.data.map(
  //     (language: any, index: any) => {
  //       return (
  //         <Menu.Item
  //           key={index}
  //           onClick={() => {
  //             i18next.changeLanguage(language.code);
  //           }}
  //         >
  //           {language.name}
  //         </Menu.Item>
  //       );
  //     }
  //   );
  //   return <Menu style={{ minWidth: 100 }}>{listDataLanguageMap}</Menu>;
  // };

  const handleLogout = () => {
    Cookies.remove("token");
    Cookies.remove("refreshToken");
    navigate("/login");
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          navigate("/mypage");
        }}
      >
        マイページ
      </Menu.Item>

      <Menu.Item key="3" onClick={handleLogout} className={styles.logout}>
        ログアウト
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.menuWrapper}>
        <div className={styles.menuItem}>
          <div className="mr-16">
            {/* <Image src={img} preview={false} /> */}
            &nbsp;
            {/* <Dropdown
              overlay={() => getListLanguageRender()}
              trigger={["click"]}
            >
              <Button>
                {t("title.language")}
                <DownOutlined />
              </Button>
            </Dropdown> */}
          </div>
          <Dropdown overlay={menu} trigger={["click"]}>
            <div>
              <span className={styles.email}>{`${
                profile?.email || "abc@gmail.com"
              }`}</span>
              &nbsp;
              <CaretDownOutlined />
            </div>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
