import { sendGet, sendPost, sendPut } from "./axios";

// eslint-disable-next-line import/prefer-default-export
export const requestAccessToken = (payload: any) =>
  sendPost("/cms/auth/request-access-token", payload);
export const login = (payload: any) => sendPost("/cms/auth/login", payload);

export const getProfile = () => sendGet("/cms/profile");

export const changePassword = (payload: any) =>
  sendPut(`/cms/auth/change-password`, payload);

export const requestVerifyCode = (email: string) =>
  sendPost(`/cms/auth/request-verified-code`, { email, type: 2 });

export const checkVerifyCode = (payload: {
  email: string;
  verifiedCode: string;
}) => sendPost(`/cms/auth/check-verified-code`, { ...payload, type: 2 });

export const resetPassword = (payload: {
  email: string;
  newPassword: string;
}) => sendPost(`/cms/auth/reset-password`, payload);

export const updateProfile = (payload: any) =>
  sendPut(`/cms/profile/edit`, payload);
