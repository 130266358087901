export const queryKey = {
  listMenu: "listMenu",
  listResource: "listResource",
  detailMenu: "detailMenu",
  listCategory: "listCategory",
  listSubCategory: "listSubCategory",
  listCoupon: "listCoupon",
  listCouponCsv: "listCouponCsv",
  detailCoupon: "detailCoupon",
  listUser: "listUser",
  detailUser: "detailUser",
  userOrderHistory: "userOrderHistory",
  userCoupon: "userCoupon",
  userStamp: "userStamp",
  userCouponDetail: "userCouponDetail",
  listCouponForStamp: "listCouponForStamp",
  listStamp: "listStamp",
  detailStamp: "detailStamp",
  listNotification: "listNotification",
  listStampAvailable: "listStampAvailable",
  detailNotification: "detailNotification",
  listSNS: "listSNS",
  listBanner: "listBanner",
  listNew: "listNew",
  detailNew: "detailNew",
  listConfigs: "listConfigs",
  listOrder: "listOrder",
  detailOrder: "detailOrder",
  listContact: "listContact",
  detailContact: "detailContact",
  listRanking: "listRanking",
  listStaff: "listStaff",
  detailStaff: "detailStaff",
  profile: "profile",
  listCouponAvailableRanking: "listCouponAvailableRanking",
  userStampDetail: "userStampDetail",
  listTemplateMail: "listTemplateMail",
  statisticStamp: "statisticStamp",
  statisticCouponNotification: "statisticCouponNotification",
  statisticCouponStamp: "statisticCouponStamp",
  listStampRelease: "listStampRelease",
  listCouponRelease: "listCouponRelease",
  listStore: "listStore",
  listMenuGroup: "listMenuGroup",
  detailMenuGroup: "detailMenuGroup",
  listDishGroupByCategory: "listDishGroupByCategory",
  listGroupMenuForDropdown: "listGroupMenuForDropdown",
};
