export enum IsSystem {
  Yes = 1,
  No = 0,
}

export enum IsVisible {
  Yes = 1,
  No = 0,
}

export enum COMMON_STATUS {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum DishOptionType {
  RADIO_BOX = 1,
  COUNTER = 2,
}

export enum CouponStatus {
  AVAILABLE = 1,
  OUTDATE = 2,
  ADD_TO_STAMP = 3,
  ADD_TO_NOTI = 4,
  PUBLISHED = 5,
  BLOCK = 6,
}

export enum CouponType {
  COMPANY = 1,
  RESTAURANT = 0,
}

export enum DateType {
  EXPIRED_DATE = 1,
  NO_EXPIRED_DATE = 2,
  EXPIRED_AFTER_DATE = 3,
}

export enum DiscountType {
  ALL_ORDER = 1,
  EACH_DISH = 2,
}

export enum CouponDishType {
  SETTING_DISCOUNT = 1,
  FREE = 2,
}

export enum TabDetail {
  PROFILE = "1",
  ORDER_HISTORY = "2",
  COUPON = "3",
  CARD_STAMP = "4",
}

export enum Gender {
  MALE = 1,
  FEMALE = 2,
}

export enum MemberCouponCMS {
  USED = 1,
  AVAILABLE = 2,
  OUTDATE = 3,
  BLOCK = 4,
}

export enum CardStampStatus {
  AVAILABLE = 1,
  OUTDATE = 2,
  ADD_TO_NOTI = 3,
  PUBLISHED = 4,
  BLOCK = 5,
}

export enum CardStampType {
  TRADE = 1,
  ACCUMULATE = 2,
}

export enum SaveStampType {
  AUTO = 1,
  SELECT = 2,
}

export enum StampExpiryType {
  HAS_OUTDATE = 1,
  INFINITY = 2,
}

export enum StampFrameNumber {
  LIMIT = 1,
  INFINITY = 2,
}

export enum Pattern {
  PATTERN_1 = 5,
  PATTERN_2 = 3,
  PATTERN_3 = 7,
}

export enum NotificationCategoryType {
  PROMOTION = 1,
  COUPON = 2,
  STAMP = 3,
  OTHER = 4,
}

export enum AutoSendNotification {
  YES = 1,
  NO = 0,
}

export enum NotificationStatus {
  AVAILABLE = 1,
  PUBLISHED = 2,
}

export enum SetTimer {
  YES = 1,
  NO = 0,
}

export enum NotificationType {
  AUTO = 1,
  BIRTHDAY = 2,
  REGISTER = 3,
  SEND_TO_SPECIFIC_RESTAURANT_USERS = 4,
  SEND_TO_USERS_HAVE_NOT_USED_APP = 5,
  SEND_TO_USERS_HAVE_USED_APP_MORE_THAN_10_TIMES = 6,
  SEND_TO_USERS_HAVE_NOT_USED_APP_IN_SPECIFIC_RESTAURANT = 7,
  SEND_TO_USERS_HAVE_USED_APP_IN_SPECIFIC_RESTAURANT = 8,
}

export enum PublishStatus {
  IMMEDIATE = 1,
  SET_TIME = 2,
}

export enum ContactStatus {
  SENT = 1,
  REPLIED = 2,
}

export enum PermissionStatus {
  EDIT = 1,
  ONLY_READ = 2,
  BLOCK = 3,
}

export enum Permissions {
  RESOURCE_MANAGEMENT = 1,
  LANGUAGE_MANAGEMENT = 2,
  MEMBER_MANAGEMENT = 3,
  MENU_MANAGEMENT = 4,
  ORDER_MANAGEMENT = 5,
  STAMP_MANAGEMENT = 6,
  COUPON_MANAGEMENT = 7,
  NOTIFICATION_MANAGEMENT = 8,
  NEWS_MANAGEMENT = 9,
  RANKING_MANAGEMENT = 10,
  CONTACT_MANAGEMENT = 11,
  STAFF_MANAGEMENT = 12,
  COMMON_MANAGEMENT = 13,
  RESTAURANT_MANAGEMENT = 14,
}

export enum MemberStampCMS {
  AVAILABLE = 1,
  OUTDATE = 2,
  BLOCK = 3,
}

export enum ExpireDate {
  DAY = 1,
  WEEK = 2,
  MONTH = 3,
  YEAR = 4,
}

export enum ExchangeCouponStampType {
  NO_LIMIT = 0,
  LIMIT = 1,
}

export enum MenuStatus {
  // INACTIVE = 0,
  ACTIVE = 1,
  PRE_PUBLISH = 2,
}

export enum RestaurantStatus {
  // INACTIVE = 0,
  ACTIVE = 1,
  PRE_OPEN = 2,
  CLOSED = 3,
}

export enum StoreApplyCouponStatus {
  ALL = 1,
  CHOSEN = 0,
}

export enum TypeTimeBanner {
  SECOND = 1,
  MINUTE = 2,
}
