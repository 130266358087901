export const emailRegex = /^(\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+)$/;
export const phoneRegex = /^\d{10,12}$/;
export const defaultTake = 10;
export const statusDefault = {
  active: "アプリ表示",
  inactive: "非表示",
};

export const text = {
  edit: "保存",
  optionAll: "すべて",
  fieldIsRequired: "こちらは必須項目です。",
  confirmDeleteMenu: "この商品を消除してもよろしいでしょうか？",
  seeMore: "もっと見る",
  unlimited: "無制限",
  couponTypeCompany: "計上",
  couponTypeRestaurant: "非計上",
  couponStatusActive: "有効",
  couponStatusOutdate: "有効期限切れ",
  couponStatusAddToStamp: "スタンプカードに追加済み",
  couponStatusAddToNoti: "通知に追加済み",
  couponStatusPublished: "発行済み",
  couponStatusDisabled: "無効",
  confirmDeleteCoupon: "クーポンを消除してもよろしいでしょうか？",
  confirmDeleteStamp: "スタンプを消除してもよろしいでしょうか？",
  couponExpire: " 有効期限設定あり",
  couponNoExpire: "無制限",
  couponAllOrder: "注文全体用の割引",
  couponEachDish: "商品割引",
  couponDishDiscount: "設定あり",
  couponDishFree: "無料",
  male: "男性",
  female: "女性",
  couponUserStatusUsed: "使用済み",
  couponUserStatusHasUse: "使用可能",
  couponUserStatusOutdate: "有効期限切れ",
  stampStatusActive: "有効",
  stampStatusOutdate: "有効期限切れ",
  stampStatusAddToNoti: "通知に追加済み",
  stampStatusPublished: "発行済み",
  stampStatusDisabled: "無効",
  stampTypeTrade: "交換型スタンプカード",
  stampTypeAccumulation: "累計型スタンプカード",
  saveStampTypeAuto: "自動付与スタンプカード",
  saveStampTypeSelect: "選択付与スタンプカード",
  stampExpiryTypeInfinity: "無期限",
  stampExpiryTypeHasOutdate: "設定あり",
  stampFrameNumberInfinity: "設定なし（無制限）",
  stampFrameNumberLimit: "設定あり",
  stampPattern1: "横５個×縦ｎ個",
  stampPattern2: "横３個×縦ｎ個",
  stampPattern3: "横７個×縦ｎ個",
  validateMin1: "最小値は１です。",
  validatePositionUnique: "入力したクーポンＧＥＴ枠は重複しています。",
  validatePositionMax:
    "「クーポンＧＥＴ枠」は「枠数入力」より小さい値をご入力ください。",
  notificationCategory_promotion: "販促",
  notificationCategory_coupon: "クーポン",
  notificationCategory_stamp: "スタンプカード",
  notificationCategory_other: "その他",
  yes: "あり",
  no: "なし",
  confirmDeleteNotification: "通知を消除してもよろしいでしょうか？",
  validateDeliverTime:
    "配信時間は現在時刻より少なくとも5分後にご設定ください。",
  image: "画像",
  title: "タイトル",
  link: "リンク",
  cropImage: "画像を切り出す",
  status: "状態",
  confirmDeleteSNS: "SNSを消除してもよろしいでしょうか？",
  confirmDeleteBanner: "バナーを消除してもよろしいでしょうか？",
  banner: "バナー",
  SNS: "SNS",
  viewMore: "詳細を見る",
  detailNew: "ニュース詳細",
  immediate: "即時配信",
  setTime: "設定時間",
  confirmDeleteNew: "ニュースを消除してもよろしいでしょうか？",
  value: "価値",
  configName: "コンフィグ名",
  config: "コンフィグ",
  confirmDeleteRanking: "ランクを消除してもよろしいでしょうか？",
  detailContact: "お問い合わせ詳細",
  waitForReply: "未返信",
  replied: "返事済",
  require: "必須",
  unrequire: "任意",
  confirmDeleteCategory: "このカテゴリーを削除してもよろしいでしょうか？",
  confirmDeleteSubcategory:
    "このサブカテゴリーを削除してもよろしいでしょうか？",
  emailInvalid: "正しいメールアドレスの形式をご入力ください。",
  twoPasswordDontMatch: "パスワードとパスワード再入力が異なっています。",
  minLengthIs6: "Min length is 6",
  confirmDeleteStaff: "こちらのスタッフを消除してもよろしいでしょうか？",
  validatePassword:
    "パスワードは8～20桁の半角文字、半角数字の2種類を組み合わせてください。",
  detailDishOrder: "注文の詳細",
  error: "エラー",
  success: "成功",
  invalidCode: "認証コードが正しくありません.",
  validateCreateRank:
    "新しいランクの累計金額は既存のランクの金額より大きくする必要があります。",
  validateRankMoney: "Invalid rank money",
  validateFullNameRegex: "名前には特殊文字を含むことはできません。",
  validateWhiteSpace: "こちらは必須項目です。",
  userStampStatusAvailable: "配信中",
  userStampStatusOutDate: "有効期限切れ",
  couponInvalid:
    "「クーポン有効期間終了日＞スタンプカード期間終了日」の必要があります。",
  confirmDisableCoupon: "このクーポンを無効にしてもよろしいでしょうか？",
  confirmDisableStamp: "このスタンプカードを無効にしてもよろしいでしょうか？",
  titleMail: "メールの件名",
  contentMail: "メールの内容",
  descriptionMail: "メールのタイプ",
  templateMail: "メールテンプレート",
  requiredContentTemplateMail:
    "メールの内容には「{code}」を含める必要があります。",
  confirmBlockMember: "このユーザーをブロックしてもよろしいでしょうか？",
  confirmUnBlockMember: "このユーザーをブロック解除してもよろしいでしょうか？",
  upload: "インポート",
  editImage: "画像を編集する",
  confirm: "確認",
  amountOrder: "ユーザー購入数",
  amountUsed: "ユーザー利用数",
  restaurantName: "店舗名",
  statistic: "スタンプカードの状況分析",
  statisticNotificationCoupon:
    "通知機能を用いて、直接配信したクーポンの状況分析",
  statisticStampCoupon: "スタンプカードに設定されているクーポンの状況分析",
  notificationObjectToSend: "通知を送信する対象",
  notificationUserSelected: "ユーザを指定する",
  durationDays: "日",
  durationWeeks: "週",
  durationMonths: "月",
  durationYear: "年",
  optionUserSelected: "指定のユーザー",
  expiryType: "有効期間失効の単位を選択する",
  expiryNumber: "有効期間失効の期間を選択する",
  expiryTypeStamp: "貯めたスタンプの有効期間失効の単位を選択する",
  expiryNumberStamp: "貯めたスタンプの有効期間失効の期間を選択する",
  errorExpiryTime: "有効期間失効日は必ず有効期間以内にしてください。",
  exchangeLimit: "交換回数",
  typeExchangeLimit: "交換制限",
  noLimit: "無制限",
  limit: "制限有り",
  hasSetting: "設定あり",
  sinceDateReceiveCoupon: "クーポンを獲得した日から",
  chooseExpireStamp: "貯めたスタンプの有効期間を設定する",
  confirmEditBirthday: "生年月日の再度変更を許可してよろしいでしょうか？",
  sendToSpecificRestaurantUsers:
    "よく利用している特定の店舗に選択しているユーザー",
  sendToUsersNotUsedApp: "アプリを利用した事のないユーザー",
  sendToUserHaveUsedAppMoreThan10Times: "特定回数以上利用した事のあるユーザー",
  sendToUserHaveNotUsedAppInSpecificRestaurant:
    "特定の店舗でアプリを利用した事のないユーザー",
  sendToUserHaveUsedAppInSpecificRestaurant:
    "特定の店舗でアプリを利用した事のあるユーザー",
  restaurantStatusActive: "営業中",
  restaurantStatusPreOpen: "開店準備",
  restaurantStatusClosed: "閉店",
  menuStatusActive: "アクティブ",
  storeApply: "利用可能店舗指定",
  allStore: "指定なし",
  chosenStore: "特定の店舗を指定する",
  couponApplyAllRestaurant: "指定なし",
  couponApplySpecifyRestaurant: "特定の店舗を指定する",
  restaurantApply: "利用可能店舗",
  validateMinute: "分の単位は5の倍数でご入力ください。",
  notSpecifyRestaurant: "店舗指定なし",
  typeTimeBanner: "時間の単位",
  typeTimeBannerSecond: "秒",
  typeTimeBannerMinute: "分",
  couponApplyForAllRestaurant: "全店舗利用可能",
  couponUseless: "ありません",
  validateSendNewMemberTime:
    "自動配信の配信終了日時は上の予約日時以降でなければなりません。",
  settingMenu: "メニューグループ設定",
  addStore: "店舗追加",
  menuStatusPrePublish: "開店準備",
  searchMenu: "メニューグループを検索",
  searchRestaurant: "店舗名を検索",
  totalOfMenu: "メニューグループ数",
  totalOfRestaurant: "店舗数",
  storeCode: "店舗ＣＤ",
  storeName: "店舗名",
  address: "住所",
  publishAt: "アプリ対応開始日",
  closingDate: "閉店日",
  editStore: "店舗編集",
  editStoreMenu: "メニューグループ設定",
  settingMenuTable: "メニュー設定",
  menu: "メニューグループの選択",
  confirmUpdateMenu: "メニューグループを変更しますが、宜しいでしょうか？",
  validateCloseDate: "閉店日はアプリに対応開始日以降でなければなりません。",
  menuFuture: "変更後のメニューグループ",
  storeMenuManage: "メニューグループ管理",
  codeGroup: `メニュー\nグループＣＤ`,
  groupMenuName: "メニューグループ名",
  addStoreMenuGroup: "メニューグループ追加",
  publishDate: "変更日の予約",
  listDish: "商品名",
  editStoreMenuGroup: "メニューグループ編集",
  viewStoreMenuGroup: "メニューグループ閲覧",
  minimumOneDish: "1つ以上の商品を選択してください。",
  current: "現在のメニューグループ",
  future: "変更後のメニューグループ",
  minTimeUsedApp: "最低使用回数",
  addStoreConfirm: "設定した内容で店舗を追加しますか？",
  editStoreConfirm: "設定した内容で更新しますか？",
};
