import React, { useEffect } from "react";
import "./App.scss";
import {
  unstable_HistoryRouter as HistoryRouter,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { QueryClientProvider, QueryClient } from "react-query";
import RootWrapper from "wrappers/RootWrapper";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
      retry: false,
    },
  },
});

export const history = createBrowserHistory();

function App() {
  // Fix ResizeObserver loop limit exceeded
  useEffect(() => {
    window.addEventListener("error", (e) => {
      if (e.message === "ResizeObserver loop limit exceeded") {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute("style", "display: none");
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute("style", "display: none");
        }
      }
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <HistoryRouter history={history}>
        <RootWrapper />
      </HistoryRouter>
    </QueryClientProvider>
  );
}

export default App;
