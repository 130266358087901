import { Spin } from "antd";

import React, { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthWrapper from "./../AuthWrapper/index";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useQuery } from "react-query";

const Login = lazy(() => import("pages/Login"));
const ForgetPassword = lazy(() => import("pages/ForgetPassword"));

export default function RootWrapper() {
  const token = Cookies.get("token") || "";
  const isAuthenticated = Boolean(token);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <div>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/*" element={<AuthWrapper />} />
        </Routes>
      </Suspense>
    </div>
  );
}
